/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, TitleMain, Text, Button, Image, Title } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"muzeum-meteoritu.cz - O nás"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column name={"prázdná"} layout={"l30"}>
        </Column>


        <Column className="css-1qerhpk js-anim  --anim3 --anim-s3 --full" style={{"marginBottom":0}} anim={"3"} name={"5z6rakqkckw"} animS={"3"} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37955/492fa89c8f69492aa725cb7ce4ad2406_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37955/492fa89c8f69492aa725cb7ce4ad2406_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37955/492fa89c8f69492aa725cb7ce4ad2406_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37955/492fa89c8f69492aa725cb7ce4ad2406_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37955/492fa89c8f69492aa725cb7ce4ad2406_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37955/492fa89c8f69492aa725cb7ce4ad2406_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37955/492fa89c8f69492aa725cb7ce4ad2406_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37955/492fa89c8f69492aa725cb7ce4ad2406_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex js-anim  --anim3 --anim-s3 --center el--1 flex--center" style={{"paddingBottom":29}} anim={"3"} animS={"3"} columns={"1"}>
            
            <ColumnWrapper >
              
              <TitleMain className="title-box title-box--center fs--62 title-box--invert" content={"<span style=\"color: var(--white); font-weight: bold;\"><br><br><br><br>O nás</span>"}>
              </TitleMain>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="js-anim  --anim3 --anim-s3" style={{"paddingTop":0,"paddingBottom":56,"backgroundColor":"rgba(0, 0, 0, 1)"}} anim={"3"} name={"5alng2xvc3e"} animS={"3"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Text className="text-box text-box--center fs--14" style={{"maxWidth":657,"paddingBottom":89}} content={"<span style=\"font-weight: bold;\"><br>Jsem Jaroslav FILIP, vesmírný nadšenec. <br>Nejvíc mě fascinuje to, co z vesmíru padá a tak si jdu za svým snem.<br><br>&nbsp;Před 2,5 lety jsme otevřeli unikátní Muzeum meteoritů.<br>No a letos máme ke 3. výročí velké plány.<br><br>Naše největší výzva je tady.&nbsp;&nbsp;</span><br><span style=\"font-weight: bold;\">Teď se ukáže, jakým směrem se vydáme dál.<br><br>Jdeš do toho s námi?<br><br>Jsme malý, ale skvělý tým lidí, který má chuť dokázat velké věci. <br>Vzděláváme všechny věkové skupiny. <br>Pořádáme také akce s pozorováním v Beskydech.</span><br><br><br>&nbsp;<span style=\"font-weight: bold;\">Tvoříme komunitu nadšenců a fanoušků vesmíru. <br>Na našem Facebooku nás je téměř 9,5 tisíce. Probíhají zde taky pravidelné soutěže o kousek vesmíru ve formě meteoritu nebo šperku.</span><br><br><span style=\"font-weight: bold;\">&nbsp;Děkuji, že můžu dělat něco, co mě neskutečně fascinuje a baví.</span><br><br>&nbsp;<br><span style=\"font-weight: bold;\">Děkuji za každou pomoc<br></span><br>&nbsp;Jaroslav FILIP"}>
              </Text>

              <Button className="btn-box" url={"https://muzeum-meteoritu.cz/sbirka-donio"} href={"https://muzeum-meteoritu.cz/sbirka-donio"} content={"Podpoř nové Muzeum"} target={null}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37955/799953926e0f4c3b9d5be48490e4f3d8_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/37955/799953926e0f4c3b9d5be48490e4f3d8_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37955/799953926e0f4c3b9d5be48490e4f3d8_s=660x_.jpg 660w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="js-anim  --anim3 --anim-s3 pb--60 pt--60" style={{"backgroundColor":"rgba(0, 0, 0, 1)"}} anim={"3"} name={"ui6927vbd2e"} animS={"3"} layout={"l8"}>
          
          <ColumnWrap className="column__flex --center el--4" columns={"4"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37955/23711f8547944094a2c31fb38b0de46a_s=660x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/37955/23711f8547944094a2c31fb38b0de46a_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37955/23711f8547944094a2c31fb38b0de46a_s=660x_.jpg 660w, https://cdn.swbpg.com/t/37955/23711f8547944094a2c31fb38b0de46a_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37955/b75ba0a8edc54bc5889e26313b252e6c_s=660x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/37955/b75ba0a8edc54bc5889e26313b252e6c_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37955/b75ba0a8edc54bc5889e26313b252e6c_s=660x_.jpg 660w, https://cdn.swbpg.com/t/37955/b75ba0a8edc54bc5889e26313b252e6c_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37955/fc6db204e9294ad1bd867d5705a028d9_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/37955/fc6db204e9294ad1bd867d5705a028d9_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37955/fc6db204e9294ad1bd867d5705a028d9_s=660x_.jpg 660w, https://cdn.swbpg.com/t/37955/fc6db204e9294ad1bd867d5705a028d9_s=860x_.jpg 860w, https://cdn.swbpg.com/t/37955/fc6db204e9294ad1bd867d5705a028d9_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37955/47ff7363dc414a33b3caac7149553105_s=350x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/37955/47ff7363dc414a33b3caac7149553105_s=350x_.jpg 350w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37955/3db73ac99cc64d459a0ec7707d5a6b66_s=660x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/37955/3db73ac99cc64d459a0ec7707d5a6b66_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37955/3db73ac99cc64d459a0ec7707d5a6b66_s=660x_.jpg 660w, https://cdn.swbpg.com/t/37955/3db73ac99cc64d459a0ec7707d5a6b66_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37955/8892e8938d3b4e14b9f935fdb9a88134_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/37955/8892e8938d3b4e14b9f935fdb9a88134_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37955/8892e8938d3b4e14b9f935fdb9a88134_s=660x_.jpg 660w, https://cdn.swbpg.com/t/37955/8892e8938d3b4e14b9f935fdb9a88134_s=860x_.jpg 860w, https://cdn.swbpg.com/t/37955/8892e8938d3b4e14b9f935fdb9a88134_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37955/9477ddc9a08f4678b87a1596cc075406_s=660x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/37955/9477ddc9a08f4678b87a1596cc075406_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37955/9477ddc9a08f4678b87a1596cc075406_s=660x_.jpg 660w, https://cdn.swbpg.com/t/37955/9477ddc9a08f4678b87a1596cc075406_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37955/574bdfe176434215b6af54bab6014cbb_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/37955/574bdfe176434215b6af54bab6014cbb_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37955/574bdfe176434215b6af54bab6014cbb_s=660x_.jpg 660w, https://cdn.swbpg.com/t/37955/574bdfe176434215b6af54bab6014cbb_s=860x_.jpg 860w, https://cdn.swbpg.com/t/37955/574bdfe176434215b6af54bab6014cbb_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center" style={{"paddingTop":0,"paddingBottom":0}} name={"svt6b7unnki"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center fs--20" content={"Muzeum Meteoritů s.r.o.   © 2024 Vytvořeno s ❤️ k vesmíru"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}